import { Location } from '@reach/router'
import React from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'

import { graphql } from 'gatsby'
import Article from '../components/Article/Article'

import heroImg from '../components/Article/exampleheaderbanner.png'
import { LocaleTypes } from '../data/locales'

interface IndexProps {
  data: {
    articles: {
      nodes: [{
        title: string
        subtitle: string
        fullWidth: boolean | null
        hero: boolean | null
        heroTextColor: string | null
        locale: LocaleTypes
        heroImg: {
          localFile: {
            publicURL: string
          }
        } | null
        content: Array<{
          body: {
            childMdx: {
              body: any
              excerpt: string
              timeToRead: string
              tableOfContents: any
            }
          }
          tabTitle: string
        }>
        updatedAt: string
      }
    ]
  }
  locale: LocaleTypes
    location: Location
  }
  pathContext: {
    localeCode: LocaleTypes
  }
}

const IndexPage: React.FC<IndexProps> = ({data, pathContext}) => {
  const article = data.articles.nodes.filter((node: any) => node.locale === pathContext.localeCode)[0]

  return (
    <Location>
      {
        ({location}) =>
          <Layout location={location} locale={pathContext.localeCode}>
            <SEO/>

            <Article
              title={article.title}
              subtitle={article.subtitle}
              contents={article.content}
              fullWidth={article.fullWidth ? article.fullWidth : false}
              country={'CL'}
              hero={article.hero ? article.hero : undefined}
              heroTextColor={article.heroTextColor ? article.heroTextColor : undefined}
              heroImg={article.heroImg ? article.heroImg.localFile.publicURL : undefined}
            />
          </Layout>
      }
    </Location>
  )
}

export default IndexPage

export const query = graphql`
  {
    articles: allContentfulArticle(filter: {slug: {eq: "home"}}) {
      nodes {
        title
        subtitle
        fullWidth
        hero
        heroTextColor
        locale: node_locale
        heroImg {
          localFile {
            publicURL
          }
        }
        content {
          body {
            childMdx {
              body
              excerpt
              timeToRead
              tableOfContents(maxDepth: 4)
            }
          }
          updatedAt(formatString: "DD MMMM YYYY")
          tabTitle
        }
      }
    }
  }
`
